import React, { useEffect, useMemo, useState } from 'react'
import { ResponsiveRow, RowBetween, RowFixed } from '../../components/Row'
import styled from 'styled-components'
import { Card } from '../../components/LineChart'
import LineChart from '../../components/LineChart/alt'
import { HideSmall, TYPE } from '../../theme'
import { MonoSpace } from '../../components/shared'
import { formatAmount, formatDollarAmount } from '../../utils/numbers'
import { unixToDate } from '../../utils/date'
import { Contract } from '@ethersproject/contracts'
import Percent from '../../components/Percent'
import { POOL_ADDRESS, useUsdtContract, useWbtccContract } from '../../hooks/useContract'
import { formatEther, formatUnits } from 'ethers/lib/utils'
import { useActiveWeb3React } from '../../hooks'

const PageWrapper = styled.div`
  width: 90%;
  margin-top: -60px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: auto;
    width: 100%;
  `};
`

const ChartWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `};

`

const AutoColumn = styled.div<{
    $gap?: 'sm' | 'md' | 'lg' | string
    justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}>`
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: ${({ $gap }) =>
        ($gap === 'sm' && '8px') || ($gap === 'md' && '12px') || ($gap === 'lg' && '24px') || $gap};
    justify-items: ${({ justify }) => justify && justify};
  `

const ThemedBackgroundGlobal = styled.div<{ $backgroundColor: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ $backgroundColor }) =>
        `radial-gradient(50% 50% at 50% 50%, ${$backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-150vh);
`

export const DarkGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg0};
`

export default function Overview() {
    const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
    const [leftLabel, setLeftLabel] = useState<string | undefined>()
    const [usdtBalance, setUsdtBalance] = useState<bigint>()
    const [wbtccBalance, setWbtccBalance] = useState<bigint>()
    const [btccPrice, setBtccPrice] = useState<any[]>([])
    const { account } = useActiveWeb3React()
   
    const activeNetwork = {
        bgColor: "#fc077d",
        primaryColor: "#fc077d",
        secondaryColor: "#2172E5",
    }
    const usdtContract: Contract | null = useUsdtContract()
    const wbtccContract: Contract | null = useWbtccContract()

    useEffect(() => {
        getBtccPrice()
    }, [])

    async function getUsdtBalance() {
        if (!usdtContract) throw new Error('missing dependencies')
        const value = await usdtContract.balanceOf(POOL_ADDRESS)
        setUsdtBalance(value)
    }

    async function getWbtccBalance() {
        if (!wbtccContract) throw new Error('missing dependencies')
        const value = await wbtccContract.balanceOf(POOL_ADDRESS)
        setWbtccBalance(value)
    }

    useEffect(() => {
        if(!account) return
        getUsdtBalance();
    }, [usdtContract, account])

    useEffect(() => {
        if(!account) return
        getWbtccBalance();
    }, [wbtccContract, account])


    const tvlValue = useMemo(() => {
        if (liquidityHover) {
            return formatDollarAmount(liquidityHover, 2, true)
        } else {
            return  '-'
        }
    }, [liquidityHover])

    const getBtccPrice = async () => {
        try {
            const response = await fetch('https://service.bitcoincode.technology/system/open/api/priceReport', {
                method: 'POST',
            }).then(res=>res.json())
            if(response?.code === 200 && response?.data?.length > 0) {
                setBtccPrice(response.data)
            }
        } catch (error) {
            return
        }
    }

    const totalValue = (usdtBalance) ? formatAmount(Number(formatUnits(usdtBalance, 6)) * 2) : '--'
    const usdtValue = usdtBalance ? formatAmount(Number(formatUnits(usdtBalance, 6))) : '--'
    const wbtccValue = wbtccBalance ? formatAmount(Number(formatEther(wbtccBalance))) : '--'

    const formattedBtccPriceData = useMemo(() => {
        if (btccPrice) {
            const formatted = btccPrice.map((day) => {
                return {
                    time: unixToDate(day.timestamp),
                    value: day.price,
                }
            })
            const filtered = filterLastItemsByTime(formatted)
            return filtered
        } else {
            return []
        }
    }, [btccPrice])

    const protocolData = {
        btccPrice: formattedBtccPriceData?.slice(-1)[0]?.value,
        btccPriceChange: formattedBtccPriceData?.length > 1 ? (formattedBtccPriceData.slice(-1)[0]?.value - formattedBtccPriceData.slice(-2)[0]?.value) : 0
    }

    useEffect(() => {
        if (liquidityHover === undefined && protocolData) {
          setLiquidityHover(protocolData.btccPrice)
        }
      }, [liquidityHover, protocolData])

    return (
        <PageWrapper id="overviw-page">
            <ThemedBackgroundGlobal $backgroundColor={activeNetwork.bgColor} />
            <AutoColumn $gap="16px">
                <TYPE.main>BTCC Overview</TYPE.main>
                <ResponsiveRow>
                    <DarkGreyCard>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">Total Pool Size</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{totalValue}</MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                    <DarkGreyCard style={{ margin: 'auto 16px' }}>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">USDT Value in Pool</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{usdtValue} </MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                    <DarkGreyCard>
                        <AutoColumn $gap="4px">
                            <TYPE.mediumHeader fontSize="16px">BTCC Value in Pool</TYPE.mediumHeader>
                            <TYPE.largeHeader fontSize="32px">
                                <MonoSpace>{wbtccValue} </MonoSpace>
                            </TYPE.largeHeader>
                        </AutoColumn>
                    </DarkGreyCard>
                </ResponsiveRow>
                <ResponsiveRow>
                    <ChartWrapper>
                        <LineChart
                            value={liquidityHover}
                            data={formattedBtccPriceData}
                            height={220}
                            minHeight={332}
                            color={activeNetwork.primaryColor}
                            label={leftLabel}
                            setValue={setLiquidityHover}
                            setLabel={setLeftLabel}
                            topLeft={
                                <AutoColumn $gap="4px">
                                    <TYPE.mediumHeader fontSize="16px">BTCC Price</TYPE.mediumHeader>
                                    <TYPE.largeHeader fontSize="32px">
                                        <MonoSpace>{tvlValue} </MonoSpace>
                                    </TYPE.largeHeader>
                                    <TYPE.main fontSize="12px" height="14px">
                                        {leftLabel ? <MonoSpace>{leftLabel} (UTC)</MonoSpace> : null}
                                    </TYPE.main>
                                </AutoColumn>
                            }
                        />
                    </ChartWrapper>
                </ResponsiveRow>
                <HideSmall>
                    <DarkGreyCard>
                        <RowBetween>
                            <RowFixed>
                                <RowFixed mr="20px">
                                    <TYPE.main mr="4px">BTCC Price 24H: </TYPE.main>
                                    <TYPE.label mr="4px">{formatDollarAmount(protocolData?.btccPrice)}</TYPE.label>
                                    <Percent value={protocolData?.btccPriceChange} wrap={true} />
                                </RowFixed>
                            </RowFixed>
                        </RowBetween>
                    </DarkGreyCard>
                </HideSmall>
            </AutoColumn>
        </PageWrapper>
    )
}


interface DataItem {
    time: string;
    value: number;
}

function filterLastItemsByTime(items: DataItem[]): DataItem[] {
    const lastItemsMap = new Map<string, DataItem>();

    items.forEach(item => {
        lastItemsMap.set(item.time, item);
    });

    return Array.from(lastItemsMap.values());
}