import { ChainId } from 'skylar-cod20-swap-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BSC_TESTNET]: '0x2AE9C0F30A1815EB54A9716Bf188f26023a6Db13',
  [ChainId.BTCC_TESTNET]: '0x4C9f525ca0eCE52233576520f2F02036855Adf69',
  [ChainId.BTCC_MAIN]: '0x8520c85b51Ba1A1305653A6Ca25124dB7713Ac53',
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
